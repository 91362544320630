<template>
    <div>
        <b-button variant="primary" v-if="manage" v-b-modal.add-notification-type>
            <span class="text-nowrap">Add Notification Type</span>
        </b-button>
        <b-modal id="add-notification-type" hide-footer size="md" centered title="Add Notification Type"
            @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">
                    {{ errorMsg }}
                </b-alert>
                <p class="mb-0">Notification Type Name</p>
                <b-form-input class="mb-1" v-model="notificationTypeName" placeholder="Notification Type Name" type="text" />
                <p class="mb-0">Notification Type Description</p>
                <b-form-textarea v-model="notificationDescription" rows="6" placeholder="Enter a detailed description"></b-form-textarea>
                <div class="modal-footer">
                    <b-button @click="addNotificationType" :disabled="loading" variant="primary">
                        <b-spinner v-if="loading" small class="mr-1" />
                        Add
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BAlert,
    BSpinner,
    BFormTextarea,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BButton,
        BFormInput,
        BAlert,
        BSpinner,
        ToastificationContent,
        BFormTextarea,
    },
    data() {
        return {
            notificationTypeName: "",
            notificationDescription: "",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
        };
    },
    computed: {
        hasError() {
            return this.errorMsg !== "";
        },
    },
    methods: {
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.notificationTypeName = "";
            this.notificationDescription = "";
            this.errorMsg = "";
        },
        async addNotificationType() {
            if (!this.checkValidation()) {
                return;
            }

            this.loading = true;

            try {
                const payload = {
                    name: this.notificationTypeName,
                    description: this.notificationDescription,
                };

                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}/notification-type`,
                    payload,
                    {
                        headers: {
                            Authorization: this.token,
                        },
                    }
                );

                if (response.data.status === 200) {
                    this.alertMessageToast = response.data.message;
                    this.showToast("success");
                    this.$emit("fetchNotificationTypes");
                    this.$bvModal.hide("add-notification-type");
                }
            } catch (error) {
                this.handleError(error);
            } finally {
                this.loading = false;
            }
        },
        checkValidation() {
            if (!this.notificationTypeName.length) {
                this.errorMsg = "Notification Type name is required.";
                return false;
            }

            if (this.notificationDescription.length < 50) {
                this.errorMsg = "Notification Description must be at least 50 characters.";
                return false;
            }

            return true;
        },
        handleError(error) {
            if (error.response) {
                this.errorMsg = error.response.data.error || "An error occurred";
            } else if (error.request) {
                this.errorMsg = "No response from server. Check your network connection.";
            } else {
                this.errorMsg = `Error setting up request: ${error.message}`;
            }
            this.alertMessageToast = this.errorMsg;
            this.showToast("danger");
        },
    },
    props: ["manage", "token"],
};
</script>
